import axios from 'axios'
import { config } from 'src/config'
import { handleServerApiError } from 'src/utils/api/interceptor/error'
import { provideXOrigin } from 'src/utils/api/interceptor/origin'
import { STAGING_URL, PRODUCTION_URL, STAGING} from 'src/utils/api/constants'

const env = process.env.NODE_ENVIRONMENT_VARIBALE as 'production' | 'staging';
const rtmApiUrl = env === STAGING ? STAGING_URL : PRODUCTION_URL;

export const appClient = axios.create({ baseURL: config.chatsupportApiUrl })
appClient.interceptors.request.use(provideXOrigin)
appClient.interceptors.response.use(undefined, handleServerApiError)

export const authClient = axios.create({ baseURL: config.authApiUrl })

export const rtmClient = axios.create({ baseURL: rtmApiUrl })

export const googleClient = axios.create({ baseURL: config.googleApiUrl })