"use strict";

var _typeof =
  typeof Symbol === "function" && typeof Symbol.iterator === "symbol"
    ? function (obj) {
        return typeof obj;
      }
    : function (obj) {
        return obj &&
          typeof Symbol === "function" &&
          obj.constructor === Symbol &&
          obj !== Symbol.prototype
          ? "symbol"
          : typeof obj;
      };

const SECOND_IN_MILLIS = 1000;
const PING_PONG_INTERVAL = 45 * SECOND_IN_MILLIS;
const PING_VERIFICATION_TIMER = 43 * SECOND_IN_MILLIS;

//EventEmitter functionality
var EventEmitter = function EventEmitter() {
  this.events = {};
};

EventEmitter.prototype.addEventListener = EventEmitter.prototype.on = function (
  event,
  listener
) {
  if (_typeof(this.events[event]) !== "object") {
    this.events[event] = [];
  }

  this.events[event].push(listener);
};

EventEmitter.prototype.off = EventEmitter.prototype.removeListener = function (
  event,
  listener
) {
  var idx;

  if (_typeof(this.events[event]) === "object") {
    idx = this.events[event].indexOf(listener);

    if (idx > -1) {
      this.events[event].splice(idx, 1);
    }
  }
};

EventEmitter.prototype.emit = function (event) {
  var i,
    listeners,
    length,
    args = [].slice.call(arguments, 1);

  if (_typeof(this.events[event]) === "object") {
    listeners = this.events[event].slice();
    length = listeners.length;

    for (i = 0; i < length; i++) {
      listeners[i].apply(this, args);
    }
  }
};

EventEmitter.prototype.once = function (event, listener) {
  this.on(event, function g() {
    this.removeListener(event, g);
    listener.apply(this, arguments);
  });
};
("use strict");

var _typeof =
  typeof Symbol === "function" && typeof Symbol.iterator === "symbol"
    ? function (obj) {
        return typeof obj;
      }
    : function (obj) {
        return obj &&
          typeof Symbol === "function" &&
          obj.constructor === Symbol &&
          obj !== Symbol.prototype
          ? "symbol"
          : typeof obj;
      };

var Utils = {
  inherits: function inherits(child, parent) {
    child.prototype = Object.create(parent.prototype);
  },
  checkApiKey: function checkApiKey(apiKey) {
    if (typeof apiKey !== "string" || !apiKey.length) {
      throw new RTMServerException("Invalid ApiKey");
    }
    return apiKey;
  },
  parseOptions: function parseOptions(userOptions, options) {
    var userSettings = {};
    userOptions = userOptions || {};
    for (var key in options) {
      if (options.hasOwnProperty(key)) {
        if (userOptions.hasOwnProperty(key)) {
          userSettings[key] = userOptions[key];
        } else {
          userSettings[key] = options[key];
        }
      }
    }
    return userSettings;
  },
  getWebsocketUrl: function getWebsocketUrl(apiKey, mode) {
    if ("live" === mode) {
      return "wss://rtmserver.anywhereworks.com/connection?apiKey=" + apiKey;
    } else if ("staging" === mode) {
      return "wss://stagingrtm.anywhereworks.com/connection?apiKey=" + apiKey;
    } else {
      return "ws://localhost:4080/connection?apiKey=" + apiKey;
    }
  },
  getRTMUrl: function getRTMUrl(mode) {
    const URLS = {
      live: "https://api.production-rtm.anywhereworks.com",
      staging: "https://api.staging-rtm.anywhereworks.com",
      localhost: "http://localhost:4080",
    };
  
    return URLS[mode] || URLS.localhost;
  },
  parseJson: function parseJson(data) {
    try {
      return JSON.parse(data);
    } catch (e) {
      console.error("Error occurred while parsing JSON : ", e);
      return "";
    }
  },
  stringify: function stringify(obj) {
    try {
      //Check with just obj for filtering null
      if (
        obj &&
        (typeof obj === "undefined" ? "undefined" : _typeof(obj)) === "object"
      ) {
        return JSON.stringify(obj);
      }
    } catch (e) {
      logger.error("Error occurred while stringifing obj : ", e);
      return "";
    }
  },
  createUUId: function createUUId() {
    var d = new Date().getTime();
    var uuid = "xxxxxxxx-xxxx-yyyy-yxxx-xxxxxxxxxxxx".replace(
      /[x4y]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == "x" ? r : (r & 0x7) | 0x8).toString(16);
      }
    );
    return uuid;
  },
  getSocketMessage: function getSocketMessage(channel, type, data, id, meta) {
    return {
      channel: channel,
      type: type,
      data: data,
      meta: meta,
    };
  },
  isConnectedToNetwork: function isConnectedToNetwork() {
    return navigator.onLine;
  },
  isConnectedToInternet: function isConnectedToInternet(mode, callback) {
    var xhr = new (window.ActiveXObject || XMLHttpRequest)("Microsoft.XMLHTTP");

    xhr.open(
      "HEAD",
      Utils.getRTMUrl(mode) +
        "/?rand=" +
        Math.floor((1 + Math.random()) * 0x10000),
      true
    );

    try {
      xhr.send();
      xhr.addEventListener(
        "readystatechange",
        function () {
          console.dir(xhr);
          if (xhr.readyState == 4) {
            if (xhr.status >= 200 && xhr.status < 304) {
              callback(true);
            } else {
              callback(true);
            }
          }
        },
        false
      );
    } catch (error) {
      callback(false);
    }
  },
  isValidArray: function isValidArray(array) {
    if (array && array.constructor === Array && array.length > 0) {
      return true;
    } else {
      return false;
    }
  },
};
("use strict");

var Connection = (function () {
  var state;
  var websocket;
  var socketId;
  var timeout;
  var reconnectAttempts = 0;
  var id = 0;
  var pingpong;
  var receivedPongs = [];
  let abnormalCloseSignals = [1006 , 1001 , 3001, 1005, 4001, 4003];

  function createConnection(apiKey, mode , hasPingPongFailed) {
    var self = this;
    destroyConnection.call(self, hasPingPongFailed);
    if(hasPingPongFailed) self.emit("abnormal_close");
    state = "connecting";
    self.emit(state);
    websocket = new WebSocket(Utils.getWebsocketUrl(apiKey, mode));
    window.testSocket = websocket;
    websocket.onopen = function (event) {
      console.debug("Connection to server opened successfully", event);
    };
    websocket.onclose = function (event) {
      state = abnormalCloseSignals.indexOf(event.code) !== -1 ? "abnormal_close" : "disconnected";
      self.emit(state);
      console.debug(
        "Connection to server closed code : %s reason : %s :socket : %s",
        event.code,
        event.reason,
        socketId
      );
      if (event.reason !== "delebrate-destroy") deactivatePingPong.call(self);
      else
        console.log("Not deactivating ping pong as it was delebrate destroy");

      //console.log("Closed processed for :" + websocket.socketId);
      if (event.code !== 1000 && event.code !== 4002 && event.code !== 4003) {
        console.debug("Attempting to reconnect");
        checkAndReconnect.call(self);
      }
    };
    websocket.onerror = function (event) {
      console.debug("Connection to server errored ", event);
    };
    websocket.onmessage = function (event) {
      //console.debug('Message received from server ', event);
      var message = Utils.parseJson(event.data);
      if (message.type === "server-connect") {
        socketId = message.data.socketId;
        console.log("New socket created:" + socketId);
        state = "connected";
        self.emit("connected", socketId);
        activatePingPong.call(self);
      } else if (message.type === "server-pingpong") {
        receivedPongs.push(message.data.id);
      } else if (message.type === "server-subscriptionresponse") {
        self.emit("server-subscriptionresponse", message);
      } else {
        self.emit("message", message);
      }
    };
  }

  function checkAndReconnect() {
    var self = this;
    if (reconnectAttempts >= self.maxReconnects) {
      reconnectAttempts = 0;
      state = "failed";
      self.emit("failed");
      return;
    }
    var interval = getReconnectInterval.call(self) * 1000;
    console.debug("Next Attempt in %s seconds", interval);
    self.emit("reconnect", interval);
    timeout = setTimeout(function () {
      console.info("Reconnecting client script...");
      self.reconnect();
    }, interval);
    reconnectAttempts++;
  }

  function getReconnectInterval() {
    var reconnectInterval =
      this.reconnectInterval *
      Math.pow(this.reconnectFactor, reconnectAttempts);
    reconnectInterval =
      reconnectInterval > this.maxReconnectInterval
        ? this.maxReconnectInterval
        : reconnectInterval;
    console.debug("Reconnect Interval : ", reconnectInterval);
    return reconnectInterval;
  }

  function activatePingPong() {
    var self = this;
    var lastTime = new Date().getTime();

    clearInterval(pingpong);

    pingpong = setInterval(function () {
      var currentTime = new Date().getTime();
      if (currentTime > lastTime + PING_VERIFICATION_TIMER) {
        self.sendMessage(Utils.stringify(getPingMessage(++id)));
        checkIfPongReceived.call(self, id);
        console.debug("JS Execution Resumed");
      } else {
        console.debug("JS Execution not hindered");
      }
      lastTime = currentTime;
    }, PING_PONG_INTERVAL);
  }

  function deactivatePingPong() {
    clearInterval(pingpong);
  }

  function checkIfPongReceived(id) {
    var self = this;
    setTimeout(
      function (id, self) {
        if (receivedPongs.indexOf(id) === -1) {
          console.debug("Pong Not Received");
          //destroyConnection.call(self);
          createConnection.call(self, self.apiKey, self.mode , true);
        } else {
          console.debug("Pong Received for Ping Sent");
          self.emit("connection-reset-success");
        }
      },
      3000,
      id,
      self
    );
  }

  function getPingMessage(id) {
    return {
      channel: "server/pingpong",
      type: "server-pingpong",
      meta: {
        id: id,
      },
    };
  }

  function destroyConnection(hasPingPongClosed) {
    if (
      websocket &&
      (websocket.readyState === 1 || websocket.readyState === 0)
    ) {
      let statusCode = hasPingPongClosed ? 4003 : 1000;
      let statusMessage = hasPingPongClosed ? "Pong Not Recieved" : "delebrate-destroy";
      console.debug("Closing connection socketId : " + websocket.socketId);
      deactivatePingPong.call(this);
      websocket.close(statusCode, statusMessage);
    }
    websocket = null;
  }

  function attachNetworkListeners() {
    window.addEventListener("online", onOnline.bind(this));
    window.addEventListener("offline", onOffline.bind(this));
  }

  function onOnline() {
    console.log("Network Up");
    var self = this;
    try {
      if (websocket) {
        self.sendMessage(Utils.stringify(getPingMessage(++id)));
        checkIfPongReceived.call(self, id);
        self.emit("networkconnected");
      } else self.emit("notifyConnectionBack");
    } catch (exception) {
      console.log("Exception occured");
      self.emit("notifyConnectionBack");
    }

    //createConnection.call( self , self.apiKey , self.mode );
  }

  function onOffline() {
    console.log("Network Down");
    var self = this;
    // destroyConnection.call( self );
    self.emit("networkdisconnected");
  }

  var Connection = function Connection(apiKey, options) {
    EventEmitter.call(this);
    this.reconnectInterval = options.reconnectInterval;
    this.reconnectFactor = options.reconnectFactor;
    this.maxReconnects = options.maxReconnects;
    this.maxReconnectInterval = options.maxReconnectInterval;
    this.apiKey = apiKey;
    this.mode = options.mode;
    createConnection.call(this, this.apiKey, this.mode);
    attachNetworkListeners.call(this);
  };

  Utils.inherits(Connection, EventEmitter);

  Connection.prototype.getState = function () {
    return state;
  };

  Connection.prototype.reconnect = function () {
    if (state !== "connected" && state !== "connecting") {
      if (Utils.isConnectedToNetwork()) {
        Utils.isConnectedToInternet(
          this.mode,
          function (available) {
            if (available) {
              createConnection.call(this, this.apiKey, this.mode);
            } else {
              throw new RTMServerException("Network Up");
            }
          }.bind(this)
        );
      } else {
        throw new RTMServerException("Network Down");
      }
    } else {
      throw new RTMServerException("Already Connected");
    }
  };

  Connection.prototype.sendMessage = function (message) {
    websocket.send(message);
  };

  Connection.prototype.disconnect = function () {
    var self = this;
    destroyConnection.call(self);
  };

  Connection.prototype.forceReconnect = function () {
    var self = this;
    destroyConnection.call(self);
    self.once("disconnected", function () {
      console.info("Reconnecting client script...");
      self.reconnect();
    });
  };
  return Connection;
})();
("use strict");

var RTMServer = (function () {
  var RTMServerInstance;
  var isSubscribed;
  var channelInstances = {};
  var defaultOptions = {
    mode: "live",
    reconnect: true,
    reconnectInterval: 1,
    maxReconnectInterval: 10,
    reconnectFactor: 2,
    maxReconnects: 10,
  };

  var RTMServer = function RTMServer(apiKey, options) {
    if (RTMServerInstance instanceof RTMServer) return RTMServerInstance;

    EventEmitter.call(this);
    this.apiKey = Utils.checkApiKey(apiKey);
    this.options = Utils.parseOptions(options, defaultOptions);

    this.connection = new Connection(this.apiKey, this.options);
    RTMServerInstance = this;

    this.connection.on("server-subscriptionresponse", function (message) {
      console.debug("Received Subscription Response : ", message);
      var channels = message.data.successfullySubscribed;
      if (channels && channels.length) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (
            var _iterator = channels[Symbol.iterator](), _step;
            !(_iteratorNormalCompletion = (_step = _iterator.next()).done);
            _iteratorNormalCompletion = true
          ) {
            var channel = _step.value;

            var pattern = channel && channel.endsWith("/*");
            if (pattern) continue;

            channelInstances[channel] = new Channel(channel);
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        RTMServerInstance.emit("subscribed", channelInstances);
      }
    });

    this.connection.on("message", function (message) {
      console.debug("Received Message : ", message);
      var channel = message.channel;
      if (channel) {
        var channelInstance = channelInstances[channel];
        if (channelInstance instanceof Channel) {
          channelInstance.emit(
            message.type || "message",
            message.data || {},
            message.userInfo || {},
            message.meta || {}
          );
        } else {
          console.debug("Received a message for unsubscribed channel", message);
        }
        RTMServerInstance.emit(
          message.type || "message",
          message.channel,
          message.data || {},
          message.userInfo || {},
          message.meta || {}
        );
      }
    });
  };

  Utils.inherits(RTMServer, EventEmitter);

  RTMServer.prototype.sendMessage = function (channel, type, data, meta) {
    var message = Utils.getSocketMessage(channel, type, data, "", meta);
    this.connection.sendMessage(Utils.stringify(message));
  };

  RTMServer.prototype.destroy = function () {
    this.connection.disconnect();
    RTMServerInstance = undefined;
  };

  var Channel = function Channel(channelName) {
    EventEmitter.call(this);
    this.channelName = channelName;
  };

  Utils.inherits(Channel, EventEmitter);

  Channel.prototype.sendMessage = function (type, data, meta) {
    var message = Utils.getSocketMessage(
      this.channelName,
      type,
      data,
      "",
      meta
    );
    RTMServerInstance.connection.sendMessage(Utils.stringify(message));
  };

  return RTMServer;
})();

var RTMServerException = function RTMServerException(message) {
  this.message = message || "";
};
export default RTMServer;
