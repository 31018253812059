const PROTOCOL: string = 'https';
const LIVE_PROJECT_NAME: string = "livesupport-app";
const STAGING_PROJECT_NAME: string = "staging-live-support";
const RTM_WEBHOOK: string = "rtm-livesupport";
const APP_HOST_URL: string = "appspot";

export const STAGING_URL = `${PROTOCOL}://donot-delete-dot-${RTM_WEBHOOK}-dot-${STAGING_PROJECT_NAME}.${APP_HOST_URL}.com`;
export const PRODUCTION_URL = `${PROTOCOL}://donot-delete-dot-${RTM_WEBHOOK}-dot-${LIVE_PROJECT_NAME}.${APP_HOST_URL}.com`;
export const STAGING = 'staging'
export const UNKNOWN_ERROR = 'Unknown error';
